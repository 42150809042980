<template>
  <el-dialog
    title="Online message"
    :visible.sync="dialogVisible"
    width="600px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div class="content onlineContact">
      <div class="name">
        <div class="left">
          <p class="p"><span class="span">* </span> First Name:</p>
          <el-input
            v-model="form.first_name"
            placeholder="Please enter First Name"
            style="width: 270px"
            size="small"
          ></el-input>
        </div>
        <div class="right">
          <p class="p"><span class="span">* </span>Last Name:</p>
          <el-input
            v-model="form.last_name"
            placeholder="Please enter Last Name"
            style="width: 270px"
            size="small"
          ></el-input>
        </div>
      </div>
      <div class="input">
        <p class="p">Phone No:</p>
        <el-input
          v-model="form.phone_no"
          placeholder="Please enter Phone No"
          style="width: 100%"
          size="small"
        ></el-input>
      </div>
      <div class="input">
        <p class="p"><span class="span">* </span>Email Address:</p>
        <el-input
          v-model="form.email_address"
          placeholder="Please enter Email address"
          style="width: 100%"
          size="small"
        ></el-input>
      </div>
      <div class="input">
        <p class="p">Mail Adress:</p>
        <el-input
          v-model="form.adress"
          placeholder="Please enter Mail Adress"
          style="width: 100%"
          type="textarea"
          size="small"
          :rows="2"
        ></el-input>
      </div>
      <div class="input">
        <p class="p"><span class="span">* </span>Message:</p>
        <el-input
          v-model="form.message"
          placeholder="Please enter Message"
          style="width: 100%"
          type="textarea"
          size="small"
          :rows="2"
        ></el-input>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose" size="small">Off</el-button>
      <el-button type="primary" @click="submit" size="small" :loading="loading"
        >Submit</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: true,
      loading: false,
      form: {
        first_name: "",
        last_name: "",
        phone_no: "",
        email_address: "",
        adress: "",
        message: "",
      },
    };
  },
  props: {
    id: [Number, String],
  },
  async created() {
    
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    submit() {
      var reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!this.form.first_name) {
        this.$message.warning("Please enter First Name");
      } else if (!this.form.last_name) {
        this.$message.warning("Please enter Last Name");
      } else if (!this.form.email_address) {
        this.$message.warning("Please enter Email address");
      } else if (!this.form.email_address.match(reg)) {
        this.$message.warning("Please enter the correct email address");
      } else if (!this.form.message) {
        this.$message.warning("Please enter Message");
      } else {
        this.loading = true;
        return this.$axios
          .post("/index/index/save_user_contact", {
            first_name: this.form.first_name,
            last_name: this.form.last_name,
            phone_no: this.form.phone_no,
            email_address: this.form.email_address,
            adress: this.form.adress,
            message: this.form.message,
          })
          .then((res) => {
            if (res.code == 1) {
              this.loading = false;
              this.handleClose();
              this.$message({
                message:
                  "Submit successfully, the staff will contact you as soon as possible, wish you a happy life ~",
                type: "success",
                duration: 3000,
              });
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  .p {
    line-height: 25px;
    font-size: 15px;
    .span {
      color: red;
    }
  }
  .name {
    display: flex;

    .right {
      margin-left: 20px;
    }
  }
  .input {
    margin-top: 15px;
  }
}
</style>
